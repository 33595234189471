import React, { useState } from "react";
import { Link as Scroller } from "react-scroll";
// import { useMediaQuery } from "react-responsive";
import classes from "../scss/Header.module.scss";

const Header = (props) => {
	const [toggleMenu, setToggleMenu] = useState(false);
	// const isDesktopOrLaptop = useMediaQuery({
	// 	query: "(min-width: 1224px)",
	// });
	const hamburgerIcon = (
		<div
			className={classes.contHam}
			onClick={() => {
				setToggleMenu(!toggleMenu);
				window.scroll();
			}}
		>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
	const closeIcon = (
		<div
			className={classes.contHam + " " + classes.hamClicked}
			onClick={() => setToggleMenu(!toggleMenu)}
		>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
	function preventScroll() {
		document.body.style.overflow = "hidden";
	}
	function resumeScroll() {
		document.body.style.overflow = "visible";
	}

	// const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return (
		<div>
			{/* code pour le mobile */}
			{props.queryMobile && (
				<div className={classes.contNav}>
					<div className={classes.logo}>
						<svg
							preserveAspectRatio="xMidYMid meet"
							data-bbox="53.38 53.82 1081.62 311.859"
							viewBox="0 0 1216 431"
							xmlns="http://www.w3.org/2000/svg"
							data-type="ugc"
							role="presentation"
							aria-hidden="true"
						>
							<g>
								<linearGradient
									gradientTransform="matrix(.9113 -.412 .412 .9113 4854.279 -1508.04)"
									y2="-324.014"
									x2="-4838.841"
									y1="-414.606"
									x1="-4877.109"
									gradientUnits="userSpaceOnUse"
									id="svgcid-582bg7-ats8fu"
								>
									<stop offset="0" stopColor="#92ce4e"></stop>
									<stop
										offset=".254"
										stopColor="#6faf2f"
									></stop>
									<stop
										offset=".53"
										stopColor="#4f9212"
									></stop>
									<stop
										offset=".683"
										stopColor="#438707"
									></stop>
								</linearGradient>
								<path
									d="M381.84 54.41c.06-.17.12-.35.16-.54-76.71-1.39-122.29 26.38-148.55 54.49-25.84 27.76-34.26 61.71-33.72 93.12 1.27 67.39 42.5 126.46 42.5 126.46-11.12-30.22-6.38-61.93-.11-90.34 6.13-27.69 16.46-54.81 29.62-79.37 26.25-48.94 65.26-91.92 110.1-103.82z"
									fill="url(#svgcid-582bg7-ats8fu)"
								></path>
								<linearGradient
									gradientTransform="rotate(35.698 -1149.482 4076.48)"
									y2="68.405"
									x2="-2181.3"
									y1="68.405"
									x1="-2284.395"
									gradientUnits="userSpaceOnUse"
									id="svgcid--sm7li5fzutuk"
								>
									<stop offset="0" stopColor="#92ce4e"></stop>
									<stop
										offset=".254"
										stopColor="#6faf2f"
									></stop>
									<stop
										offset=".53"
										stopColor="#4f9212"
									></stop>
									<stop
										offset=".683"
										stopColor="#438707"
									></stop>
								</linearGradient>
								<path
									d="M381.84 54.41c-44.81 11.89-83.82 54.86-110.09 103.79-13.16 24.56-23.49 51.68-29.63 79.37-6.28 28.42-11.02 60.13.1 90.36 0 0 28.45-9.39 61.72-45.02 17.1-18.37 33.99-44.13 47.24-82.35 13.01-37.62 14.77-88.13 30.66-146.15z"
									fill="url(#svgcid--sm7li5fzutuk)"
								></path>
								<path
									d="M239.8 227.65c.42 1.42 1.31 2.35 2 2.07 1.91-.77-2.74-14.24-3.9-18.62-5.03-18.87-14.07-58.84-12.78-75.39 1.95-15.5-11.65 3.94 14.68 91.94z"
									fill="#fffdfd"
								></path>
								<path
									d="M267.78 158.96c.25 1.36 1.19 2.5 2.08 2.52 2.47.07-.11-9.6-.79-13.75-7.51-46.33-4.61-58.65-4.61-58.65-4.09 10.79 1.79 61.58 3.32 69.88z"
									fill="#fffdfd"
								></path>
								<path
									d="M309.28 100.44c-.22 1.74 2.43 1.61 2.67-.16 2.36-18.36 3.8-15.75 6.9-25.59 2.18-4.42-7.51 10.43-9.57 25.75z"
									fill="#fffdfd"
								></path>
								<path
									d="M341.96 117.74s-16.59 8.03-50.41 7.7c-1.19-.01-2.48.83-2.56 1.85-.31 5.66 42.95-3.69 53.45-9.02.53-.27.07-.77-.48-.53z"
									fill="#ffffff"
								></path>
								<path
									d="M327.94 194.7c-.09.06-.22.09-.31.15.21-.11.31-.15.31-.15z"
									fill="#ffffff"
								></path>
								<path
									d="M255.26 191.65c-2.65 0-3.28 3.86-.63 3.88 10.17.98 50.12-2.42 72.23-14.84-2.87 1.35-26.03 11.88-68.5 11.04-.34 0-2.87-.06-3.1-.08z"
									fill="#ffffff"
								></path>
								<path
									d="M245 233.91c-2.65 0-3.28 3.86-.63 3.88 10.17.98 50.12-2.42 72.23-14.84-2.87 1.35-26.03 11.88-68.5 11.04-.34.01-2.87-.05-3.1-.08z"
									fill="#ffffff"
								></path>
								<path
									d="M343.06 73.53c-33.56 21.33-59.83 57.17-80.69 98.14-10.46 20.58-17.69 37.32-23.07 61.88-5.5 25.15-13.52 57.18-2.33 88.91 6.69 12.59 4.22 25.75 5.6 25.15.79-.34.5-16.49-.16-19.19-14.37-43.22-3.45-82.61 6.26-114.24 9.55-31.06 23.16-57.68 37-78.84 27.79-42.41 57.58-63.01 58.91-61.6-.36-.41-1.05-.51-1.52-.21z"
									fill="#ffffff"
								></path>
								<linearGradient
									gradientTransform="scale(-1 1) rotate(-7.671 -74.206 -55546.978)"
									y2="-262.968"
									x2="-7580.224"
									y1="-329.982"
									x1="-7608.531"
									gradientUnits="userSpaceOnUse"
									id="svgcid-nzxtsb691hbf"
								>
									<stop offset="0" stopColor="#92ce4e"></stop>
									<stop
										offset=".254"
										stopColor="#6faf2f"
									></stop>
									<stop
										offset=".53"
										stopColor="#4f9212"
									></stop>
									<stop
										offset=".683"
										stopColor="#438707"
									></stop>
								</linearGradient>
								<path
									d="M53.61 168.76c-.08-.11-.16-.22-.23-.34 56.15-19.33 95.06-10.92 119.81 2.35 24.38 13.12 37.14 34.73 42.82 56.7 12.11 47.18-6.79 98.14-6.79 98.14 2.33-23.68-7.28-44.6-17.4-62.87-9.87-17.8-22.71-34.18-37.14-48.12-28.78-27.74-65.78-48.31-101.07-45.86z"
									fill="url(#svgcid-nzxtsb691hbf)"
								></path>
								<linearGradient
									gradientTransform="scale(-1 1) rotate(52.358 427.226 4831.7)"
									y2="2474.13"
									x2="-3507.887"
									y1="2474.13"
									x1="-3585.425"
									gradientUnits="userSpaceOnUse"
									id="svgcid--v9b3dm-ypnckw"
								>
									<stop offset="0" stopColor="#92ce4e"></stop>
									<stop
										offset=".254"
										stopColor="#6faf2f"
									></stop>
									<stop
										offset=".53"
										stopColor="#4f9212"
									></stop>
									<stop
										offset=".683"
										stopColor="#438707"
									></stop>
								</linearGradient>
								<path
									d="M53.61 168.76c35.26-2.45 72.26 18.1 101.05 45.86 14.43 13.94 27.28 30.33 37.15 48.12 10.12 18.26 19.74 39.19 17.41 62.88 0 0-22.75.28-54.11-16.55-16.13-8.68-33.54-22.56-50.68-45.98-16.84-23.05-27.91-57.77-50.82-94.33z"
									fill="url(#svgcid--v9b3dm-ypnckw)"
								></path>
								<path
									d="M179.69 180.55c9.4 23.79 1.18 54.17-.1 60.8-.32 1.09-1.03 1.63-1.59 1.24-1.23-.85-.29-3.07.33-5.46 4.51-17.34 5.25-37.86 1.7-52.34-1.84-6.78-4.4-14.65-.34-4.24z"
									fill="#ffffff"
								></path>
								<path
									d="M146.19 202.86c.35 2.24-1.82 3.97-2.19 1.74-.83-5.22-5.65-30.46-7.33-34.66-1.09-2.7 6.07 11.37 9.52 32.92z"
									fill="#ffffff"
								></path>
								<path
									d="M191.56 268.42c-51.81 11.55-61.75 8.01-61.75 8.01 18.49 1.68 55.04-10.55 61.04-11.02 1.96-.44 2.66 2.57.71 3.01z"
									fill="#ffffff"
								></path>
								<path
									d="M89.61 202.11s13.91.48 38.34-9.18c.86-.34 1.99-.17 2.29.46 1.55 3.52-31.99 9.65-40.85 9.19-.45-.02-.24-.46.22-.47z"
									fill="#ffffff"
								></path>
								<path
									d="M166.81 228.59c.02-.01.06-.01.08-.01 2.2-.26 2.45 2.96.29 3.22-6.17-.38-38.97 5.11-53.08 1.15-10.15-2.34 9.37 2.89 52.71-4.36z"
									fill="#ffffff"
								></path>
								<path
									d="M120.39 253.45c.08.02.18.01.26.03l-.26-.03z"
									fill="#ffffff"
								></path>
								<path
									d="M81.75 170.45c28.82 6.81 54.33 23.88 77.6 47.4 11.67 11.81 21.94 26.12 30.65 41.92 8.91 16.18 21.49 29.06 19.4 53.81-2.49 10.36.42 23.57-.71 23.48-.65-.05-3.56-11.35-3.6-13.39 2.21-33.5-13.44-58.29-26.7-77.97-13.03-19.32-28.19-34.58-42.47-45.99-28.64-22.85-54.54-30.05-55.25-28.75.2-.37.68-.61 1.08-.51z"
									fill="#ffffff"
								></path>
								<path
									d="M205.4 221.72c8.93 25.23.44 57.61-.91 64.67-.33 1.16-1.04 1.75-1.58 1.34-1.19-.89-.25-3.26.4-5.81 4.67-18.48 5.67-40.31 2.37-55.69-1.72-7.2-4.13-15.54-.28-4.51z"
									fill="#ffffff"
								></path>
								<path
									d="M144.76 322.43s-11.99-2.25-11.99 2.05c0 4.29-2.47 39.72 1.41 40.14 3.88.39 35.89 2.86 38-1.44 2.11-4.31 2.64-40.55-.53-41.78-3.17-1.22-26.89 1.03-26.89 1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M241.3 363.46s27.84 2.25 27.84-2.05c0-4.29 5.73-39.72-3.28-40.14-9-.39-83.33-2.86-88.24 1.44-4.91 4.31-6.14 40.55 1.23 41.78 7.36 1.22 62.45-1.03 62.45-1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M187.08 277.49s-11.99-2.25-11.99 2.05c0 4.29-2.47 39.72 1.41 40.14 3.88.39 35.89 2.86 38-1.44 2.11-4.31 2.64-40.55-.53-41.78-3.17-1.22-26.89 1.03-26.89 1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M270.94 350.5s-2.25 12.75 2.05 12.75c4.29 0 39.72 2.62 40.14-1.5.39-4.12 2.86-38.15-1.44-40.4-4.31-2.25-40.55-2.81-41.78.56-1.22 3.37 1.03 28.59 1.03 28.59z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M283.47 318.52s27.84 2.25 27.84-2.05c0-4.29 5.73-39.72-3.28-40.14-9-.39-83.33-2.86-88.24 1.44-4.91 4.31-6.14 40.55 1.23 41.78 7.37 1.22 62.45-1.03 62.45-1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M284.76 232.43s-11.99-2.25-11.99 2.05c0 4.29-2.47 39.72 1.41 40.14 3.88.39 35.89 2.86 38-1.44 2.11-4.31 2.64-40.55-.53-41.78-3.17-1.22-26.89 1.03-26.89 1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M372.46 188.73c6.77 0 12.56 2.4 17.35 7.19S397 206.5 397 213.28c0 6.77-2.4 12.56-7.19 17.36-4.79 4.79-10.58 7.19-17.35 7.19h-14.73v29.46h-19.64v-78.55h34.37zm0 29.46c1.34 0 2.49-.48 3.46-1.45.97-.97 1.45-2.12 1.45-3.46 0-1.34-.49-2.49-1.45-3.46-.97-.97-2.12-1.45-3.46-1.45h-14.73v9.82h14.73z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M450.6 257.46h-28.87l-3.78 9.82H396.9l30.09-78.55h18.36l30.09 78.55h-21.06l-3.78-9.82zm-7.51-19.64l-6.92-18.07-6.92 18.07h13.84z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M492.57 228.35c-10.11-17.15-18.3-30.36-24.55-39.62h22.83c3.96 5.89 7.81 11.78 11.54 17.67 3.73-5.89 7.58-11.78 11.54-17.67h22.83c-6.25 9.26-14.43 22.47-24.55 39.62v38.93h-19.64v-38.93z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M569.34 188.73c9.82 0 19.64.82 29.46 2.45v19.64c-9.82-1.64-19.64-2.45-29.46-2.45-1.34 0-2.49.49-3.46 1.45-.97.97-1.45 2.12-1.45 3.46 0 1.34.49 2.5 1.45 3.46.97.97 2.12 1.45 3.46 1.45h4.91c6.77 0 12.56 2.4 17.36 7.19 4.79 4.79 7.19 10.58 7.19 17.35 0 6.77-2.39 12.56-7.19 17.36s-10.58 7.19-17.36 7.19c-9.82 0-19.64-.82-29.46-2.45v-19.64c9.82 1.64 19.64 2.45 29.46 2.45 1.34 0 2.5-.48 3.46-1.45s1.45-2.12 1.45-3.46c0-1.34-.48-2.49-1.45-3.46-.97-.97-2.12-1.45-3.46-1.45h-4.91c-6.77 0-12.56-2.39-17.35-7.19-4.79-4.79-7.2-10.58-7.2-17.36 0-6.77 2.4-12.56 7.2-17.35 4.79-4.79 10.57-7.19 17.35-7.19z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M663.3 257.46h-28.87l-3.78 9.82h-21.06l30.09-78.55h18.36l30.09 78.55h-21.06l-3.77-9.82zm-7.51-19.64l-6.92-18.07-6.92 18.07h13.84z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M735.16 188.73c8.18 0 16.36.82 24.55 2.45v19.64c-8.18-1.64-16.36-2.45-24.55-2.45-5.43 0-10.06 1.91-13.89 5.74s-5.74 8.46-5.74 13.89 1.91 10.06 5.74 13.89 8.46 5.74 13.89 5.74c1.64 0 3.27-.03 4.91-.1v-9.72h-9.82l-2.45-19.64h31.91v46.64c-8.18 1.64-16.36 2.45-24.55 2.45-10.83 0-20.09-3.83-27.77-11.51s-11.51-16.93-11.51-27.77 3.83-20.08 11.51-27.76 16.94-11.49 27.77-11.49z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M799.22 247.64h29.46v19.64h-49.09v-78.55h49.09v19.64h-29.46v9.82h24.55l-2.45 19.64h-22.09v9.81z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M868.18 230.41v36.87h-19.64v-78.55h20.08l14.29 27.44 14.29-27.44h20.08v78.55h-19.64v-36.87l-14.73 28.28-14.73-28.28z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M956.79 247.64h29.46v19.64h-49.09v-78.55h49.09v19.64h-29.46v9.82h24.55l-2.45 19.64H956.8v9.81z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1025.76 230.41v36.87h-19.64v-78.55h20.08l19.2 36.87v-36.87h19.64v78.55h-20.08l-19.2-36.87z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1119.27 208.37v58.91h-19.64v-58.91h-14.73v-19.64h49.1v19.64h-14.73z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M361.5 300.89c6.05 0 12.11.61 18.16 1.82v14.53a92.446 92.446 0 00-18.16-1.82c-4.02 0-7.45 1.42-10.28 4.25-2.83 2.83-4.25 6.26-4.25 10.28s1.42 7.45 4.25 10.28c2.83 2.83 6.26 4.25 10.28 4.25 1.21 0 2.42-.02 3.63-.07v-7.19h-7.26l-1.82-14.53h23.61v34.5a92.446 92.446 0 01-18.16 1.82c-8.01 0-14.86-2.84-20.54-8.51s-8.51-12.53-8.51-20.54 2.84-14.86 8.51-20.54 12.53-8.53 20.54-8.53z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M430.68 300.89v14.53h-21.79v7.26h19.98l-1.82 14.53h-18.16V359h-14.53v-58.11h36.32z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M481.88 344.47h21.79V359h-36.32v-58.11h14.53v43.58z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M532.9 344.47h21.79V359h-36.32v-58.11h36.32v14.53H532.9v7.26h18.16l-1.82 14.53H532.9v7.26z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M587.55 300.89c7.26 0 14.53.61 21.79 1.82v14.53c-7.26-1.21-14.53-1.82-21.79-1.82-.99 0-1.85.36-2.56 1.07s-1.07 1.57-1.07 2.56.36 1.85 1.07 2.56c.71.71 1.57 1.07 2.56 1.07h3.63c5.01 0 9.29 1.78 12.84 5.32 3.55 3.55 5.32 7.82 5.32 12.84 0 5.01-1.78 9.29-5.32 12.84-3.55 3.55-7.82 5.32-12.84 5.32-7.26 0-14.53-.61-21.79-1.82v-14.53c7.26 1.21 14.53 1.82 21.79 1.82.99 0 1.84-.36 2.56-1.07.71-.71 1.07-1.57 1.07-2.56s-.36-1.84-1.07-2.56-1.57-1.07-2.56-1.07h-3.63c-5.01 0-9.29-1.77-12.84-5.32-3.55-3.55-5.32-7.83-5.32-12.84s1.77-9.29 5.32-12.84c3.55-3.55 7.83-5.32 12.84-5.32z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M671.44 300.89c5.01 0 9.29 1.78 12.84 5.32 3.55 3.55 5.32 7.82 5.32 12.84s-1.77 9.29-5.32 12.84c-3.55 3.55-7.83 5.32-12.84 5.32h-10.9V359h-14.53v-58.11h25.43zm0 21.79c.99 0 1.85-.36 2.56-1.07.71-.71 1.07-1.57 1.07-2.56s-.36-1.84-1.07-2.56-1.57-1.07-2.56-1.07h-10.9v7.26h10.9z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M730.45 359c-8.01 0-14.86-2.84-20.54-8.51s-8.52-12.53-8.52-20.54 2.84-14.86 8.52-20.54 12.52-8.52 20.54-8.52c8.01 0 14.86 2.84 20.54 8.52s8.51 12.52 8.51 20.54c0 8.01-2.84 14.86-8.51 20.54S738.46 359 730.45 359zm0-14.53c4.02 0 7.45-1.42 10.28-4.25 2.83-2.83 4.25-6.26 4.25-10.28s-1.42-7.45-4.25-10.28c-2.83-2.83-6.26-4.25-10.28-4.25s-7.45 1.42-10.28 4.25c-2.83 2.83-4.25 6.26-4.25 10.28s1.42 7.45 4.25 10.28c2.83 2.84 6.26 4.25 10.28 4.25z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M799.63 300.89c5.01 0 9.29 1.78 12.84 5.32 3.55 3.55 5.32 7.82 5.32 12.84s-1.77 9.29-5.3 12.82c-1.67 1.67-3.5 2.95-5.48 3.85 5.54 7.75 9.69 15.51 12.42 23.28h-16.78c-2.57-7.26-6.37-14.53-11.4-21.79h-2.51V359h-14.53v-58.11h25.42zm0 21.79c.99 0 1.85-.36 2.56-1.07.71-.71 1.07-1.57 1.07-2.56s-.36-1.84-1.07-2.56-1.57-1.07-2.56-1.07h-10.9v7.26h10.9z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M854.47 315.42V359h-14.53v-43.58h-10.9v-14.53h36.32v14.53h-10.89z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M880.06 337.21v-36.32h14.53v36.32c0 2.01.71 3.72 2.12 5.14 1.42 1.42 3.13 2.12 5.14 2.12s3.72-.71 5.14-2.12c1.42-1.42 2.12-3.13 2.12-5.14v-36.32h14.53v36.32c0 6.03-2.12 11.17-6.37 15.42s-9.39 6.37-15.42 6.37-11.17-2.12-15.42-6.37c-4.24-4.25-6.37-9.39-6.37-15.42z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M967.4 300.89c6.05 0 12.11.61 18.16 1.82v14.53a92.446 92.446 0 00-18.16-1.82c-4.02 0-7.45 1.42-10.28 4.25-2.83 2.83-4.25 6.26-4.25 10.28s1.42 7.45 4.25 10.28c2.83 2.83 6.26 4.25 10.28 4.25 1.21 0 2.42-.02 3.63-.07v-7.19h-7.26l-1.82-14.53h23.61v34.5a92.446 92.446 0 01-18.16 1.82c-8.01 0-14.86-2.84-20.54-8.51s-8.52-12.53-8.52-20.54 2.84-14.86 8.52-20.54 12.53-8.53 20.54-8.53z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1036.37 351.74h-21.36l-2.8 7.26h-15.58l22.26-58.11h13.58l22.26 58.11h-15.58l-2.78-7.26zm-5.56-14.53l-5.12-13.37-5.12 13.37h10.24z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1080.34 359h-14.53v-58.11h14.53V359z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1113.21 300.89c7.26 0 14.53.61 21.79 1.82v14.53c-7.26-1.21-14.53-1.82-21.79-1.82-.99 0-1.85.36-2.56 1.07-.71.71-1.07 1.57-1.07 2.56s.36 1.85 1.07 2.56c.71.71 1.57 1.07 2.56 1.07h3.63c5.01 0 9.29 1.78 12.84 5.32s5.32 7.82 5.32 12.84c0 5.01-1.78 9.29-5.32 12.84s-7.82 5.32-12.84 5.32c-7.26 0-14.53-.61-21.79-1.82v-14.53c7.26 1.21 14.53 1.82 21.79 1.82.99 0 1.84-.36 2.56-1.07.71-.71 1.07-1.57 1.07-2.56s-.36-1.84-1.07-2.56-1.57-1.07-2.56-1.07h-3.63c-5.01 0-9.29-1.77-12.84-5.32-3.55-3.55-5.32-7.83-5.32-12.84s1.77-9.29 5.32-12.84c3.54-3.55 7.83-5.32 12.84-5.32z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
							</g>
						</svg>
					</div>
					{toggleMenu ? closeIcon : hamburgerIcon}
				</div>
			)}
			{props.queryDesktop && (
				<div className={classes.contNavD}>
					<div
						className={classes.logo}
						onClick={() => window.scrollTo(0, 0)}
						style={{
							cursor: "pointer",
						}}
					>
						<svg
							preserveAspectRatio="xMidYMid meet"
							data-bbox="53.38 53.82 1081.62 311.859"
							viewBox="0 0 1216 431"
							xmlns="http://www.w3.org/2000/svg"
							data-type="ugc"
							role="presentation"
							aria-hidden="true"
						>
							<g>
								<linearGradient
									gradientTransform="matrix(.9113 -.412 .412 .9113 4854.279 -1508.04)"
									y2="-324.014"
									x2="-4838.841"
									y1="-414.606"
									x1="-4877.109"
									gradientUnits="userSpaceOnUse"
									id="svgcid-582bg7-ats8fu"
								>
									<stop offset="0" stopColor="#92ce4e"></stop>
									<stop
										offset=".254"
										stopColor="#6faf2f"
									></stop>
									<stop
										offset=".53"
										stopColor="#4f9212"
									></stop>
									<stop
										offset=".683"
										stopColor="#438707"
									></stop>
								</linearGradient>
								<path
									d="M381.84 54.41c.06-.17.12-.35.16-.54-76.71-1.39-122.29 26.38-148.55 54.49-25.84 27.76-34.26 61.71-33.72 93.12 1.27 67.39 42.5 126.46 42.5 126.46-11.12-30.22-6.38-61.93-.11-90.34 6.13-27.69 16.46-54.81 29.62-79.37 26.25-48.94 65.26-91.92 110.1-103.82z"
									fill="url(#svgcid-582bg7-ats8fu)"
								></path>
								<linearGradient
									gradientTransform="rotate(35.698 -1149.482 4076.48)"
									y2="68.405"
									x2="-2181.3"
									y1="68.405"
									x1="-2284.395"
									gradientUnits="userSpaceOnUse"
									id="svgcid--sm7li5fzutuk"
								>
									<stop offset="0" stopColor="#92ce4e"></stop>
									<stop
										offset=".254"
										stopColor="#6faf2f"
									></stop>
									<stop
										offset=".53"
										stopColor="#4f9212"
									></stop>
									<stop
										offset=".683"
										stopColor="#438707"
									></stop>
								</linearGradient>
								<path
									d="M381.84 54.41c-44.81 11.89-83.82 54.86-110.09 103.79-13.16 24.56-23.49 51.68-29.63 79.37-6.28 28.42-11.02 60.13.1 90.36 0 0 28.45-9.39 61.72-45.02 17.1-18.37 33.99-44.13 47.24-82.35 13.01-37.62 14.77-88.13 30.66-146.15z"
									fill="url(#svgcid--sm7li5fzutuk)"
								></path>
								<path
									d="M239.8 227.65c.42 1.42 1.31 2.35 2 2.07 1.91-.77-2.74-14.24-3.9-18.62-5.03-18.87-14.07-58.84-12.78-75.39 1.95-15.5-11.65 3.94 14.68 91.94z"
									fill="#fffdfd"
								></path>
								<path
									d="M267.78 158.96c.25 1.36 1.19 2.5 2.08 2.52 2.47.07-.11-9.6-.79-13.75-7.51-46.33-4.61-58.65-4.61-58.65-4.09 10.79 1.79 61.58 3.32 69.88z"
									fill="#fffdfd"
								></path>
								<path
									d="M309.28 100.44c-.22 1.74 2.43 1.61 2.67-.16 2.36-18.36 3.8-15.75 6.9-25.59 2.18-4.42-7.51 10.43-9.57 25.75z"
									fill="#fffdfd"
								></path>
								<path
									d="M341.96 117.74s-16.59 8.03-50.41 7.7c-1.19-.01-2.48.83-2.56 1.85-.31 5.66 42.95-3.69 53.45-9.02.53-.27.07-.77-.48-.53z"
									fill="#ffffff"
								></path>
								<path
									d="M327.94 194.7c-.09.06-.22.09-.31.15.21-.11.31-.15.31-.15z"
									fill="#ffffff"
								></path>
								<path
									d="M255.26 191.65c-2.65 0-3.28 3.86-.63 3.88 10.17.98 50.12-2.42 72.23-14.84-2.87 1.35-26.03 11.88-68.5 11.04-.34 0-2.87-.06-3.1-.08z"
									fill="#ffffff"
								></path>
								<path
									d="M245 233.91c-2.65 0-3.28 3.86-.63 3.88 10.17.98 50.12-2.42 72.23-14.84-2.87 1.35-26.03 11.88-68.5 11.04-.34.01-2.87-.05-3.1-.08z"
									fill="#ffffff"
								></path>
								<path
									d="M343.06 73.53c-33.56 21.33-59.83 57.17-80.69 98.14-10.46 20.58-17.69 37.32-23.07 61.88-5.5 25.15-13.52 57.18-2.33 88.91 6.69 12.59 4.22 25.75 5.6 25.15.79-.34.5-16.49-.16-19.19-14.37-43.22-3.45-82.61 6.26-114.24 9.55-31.06 23.16-57.68 37-78.84 27.79-42.41 57.58-63.01 58.91-61.6-.36-.41-1.05-.51-1.52-.21z"
									fill="#ffffff"
								></path>
								<linearGradient
									gradientTransform="scale(-1 1) rotate(-7.671 -74.206 -55546.978)"
									y2="-262.968"
									x2="-7580.224"
									y1="-329.982"
									x1="-7608.531"
									gradientUnits="userSpaceOnUse"
									id="svgcid-nzxtsb691hbf"
								>
									<stop offset="0" stopColor="#92ce4e"></stop>
									<stop
										offset=".254"
										stopColor="#6faf2f"
									></stop>
									<stop
										offset=".53"
										stopColor="#4f9212"
									></stop>
									<stop
										offset=".683"
										stopColor="#438707"
									></stop>
								</linearGradient>
								<path
									d="M53.61 168.76c-.08-.11-.16-.22-.23-.34 56.15-19.33 95.06-10.92 119.81 2.35 24.38 13.12 37.14 34.73 42.82 56.7 12.11 47.18-6.79 98.14-6.79 98.14 2.33-23.68-7.28-44.6-17.4-62.87-9.87-17.8-22.71-34.18-37.14-48.12-28.78-27.74-65.78-48.31-101.07-45.86z"
									fill="url(#svgcid-nzxtsb691hbf)"
								></path>
								<linearGradient
									gradientTransform="scale(-1 1) rotate(52.358 427.226 4831.7)"
									y2="2474.13"
									x2="-3507.887"
									y1="2474.13"
									x1="-3585.425"
									gradientUnits="userSpaceOnUse"
									id="svgcid--v9b3dm-ypnckw"
								>
									<stop offset="0" stopColor="#92ce4e"></stop>
									<stop
										offset=".254"
										stopColor="#6faf2f"
									></stop>
									<stop
										offset=".53"
										stopColor="#4f9212"
									></stop>
									<stop
										offset=".683"
										stopColor="#438707"
									></stop>
								</linearGradient>
								<path
									d="M53.61 168.76c35.26-2.45 72.26 18.1 101.05 45.86 14.43 13.94 27.28 30.33 37.15 48.12 10.12 18.26 19.74 39.19 17.41 62.88 0 0-22.75.28-54.11-16.55-16.13-8.68-33.54-22.56-50.68-45.98-16.84-23.05-27.91-57.77-50.82-94.33z"
									fill="url(#svgcid--v9b3dm-ypnckw)"
								></path>
								<path
									d="M179.69 180.55c9.4 23.79 1.18 54.17-.1 60.8-.32 1.09-1.03 1.63-1.59 1.24-1.23-.85-.29-3.07.33-5.46 4.51-17.34 5.25-37.86 1.7-52.34-1.84-6.78-4.4-14.65-.34-4.24z"
									fill="#ffffff"
								></path>
								<path
									d="M146.19 202.86c.35 2.24-1.82 3.97-2.19 1.74-.83-5.22-5.65-30.46-7.33-34.66-1.09-2.7 6.07 11.37 9.52 32.92z"
									fill="#ffffff"
								></path>
								<path
									d="M191.56 268.42c-51.81 11.55-61.75 8.01-61.75 8.01 18.49 1.68 55.04-10.55 61.04-11.02 1.96-.44 2.66 2.57.71 3.01z"
									fill="#ffffff"
								></path>
								<path
									d="M89.61 202.11s13.91.48 38.34-9.18c.86-.34 1.99-.17 2.29.46 1.55 3.52-31.99 9.65-40.85 9.19-.45-.02-.24-.46.22-.47z"
									fill="#ffffff"
								></path>
								<path
									d="M166.81 228.59c.02-.01.06-.01.08-.01 2.2-.26 2.45 2.96.29 3.22-6.17-.38-38.97 5.11-53.08 1.15-10.15-2.34 9.37 2.89 52.71-4.36z"
									fill="#ffffff"
								></path>
								<path
									d="M120.39 253.45c.08.02.18.01.26.03l-.26-.03z"
									fill="#ffffff"
								></path>
								<path
									d="M81.75 170.45c28.82 6.81 54.33 23.88 77.6 47.4 11.67 11.81 21.94 26.12 30.65 41.92 8.91 16.18 21.49 29.06 19.4 53.81-2.49 10.36.42 23.57-.71 23.48-.65-.05-3.56-11.35-3.6-13.39 2.21-33.5-13.44-58.29-26.7-77.97-13.03-19.32-28.19-34.58-42.47-45.99-28.64-22.85-54.54-30.05-55.25-28.75.2-.37.68-.61 1.08-.51z"
									fill="#ffffff"
								></path>
								<path
									d="M205.4 221.72c8.93 25.23.44 57.61-.91 64.67-.33 1.16-1.04 1.75-1.58 1.34-1.19-.89-.25-3.26.4-5.81 4.67-18.48 5.67-40.31 2.37-55.69-1.72-7.2-4.13-15.54-.28-4.51z"
									fill="#ffffff"
								></path>
								<path
									d="M144.76 322.43s-11.99-2.25-11.99 2.05c0 4.29-2.47 39.72 1.41 40.14 3.88.39 35.89 2.86 38-1.44 2.11-4.31 2.64-40.55-.53-41.78-3.17-1.22-26.89 1.03-26.89 1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M241.3 363.46s27.84 2.25 27.84-2.05c0-4.29 5.73-39.72-3.28-40.14-9-.39-83.33-2.86-88.24 1.44-4.91 4.31-6.14 40.55 1.23 41.78 7.36 1.22 62.45-1.03 62.45-1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M187.08 277.49s-11.99-2.25-11.99 2.05c0 4.29-2.47 39.72 1.41 40.14 3.88.39 35.89 2.86 38-1.44 2.11-4.31 2.64-40.55-.53-41.78-3.17-1.22-26.89 1.03-26.89 1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M270.94 350.5s-2.25 12.75 2.05 12.75c4.29 0 39.72 2.62 40.14-1.5.39-4.12 2.86-38.15-1.44-40.4-4.31-2.25-40.55-2.81-41.78.56-1.22 3.37 1.03 28.59 1.03 28.59z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M283.47 318.52s27.84 2.25 27.84-2.05c0-4.29 5.73-39.72-3.28-40.14-9-.39-83.33-2.86-88.24 1.44-4.91 4.31-6.14 40.55 1.23 41.78 7.37 1.22 62.45-1.03 62.45-1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M284.76 232.43s-11.99-2.25-11.99 2.05c0 4.29-2.47 39.72 1.41 40.14 3.88.39 35.89 2.86 38-1.44 2.11-4.31 2.64-40.55-.53-41.78-3.17-1.22-26.89 1.03-26.89 1.03z"
									fill="#ff5555"
									stroke="#ffffff"
									strokeWidth="2"
									strokeMiterlimit="10"
								></path>
								<path
									d="M372.46 188.73c6.77 0 12.56 2.4 17.35 7.19S397 206.5 397 213.28c0 6.77-2.4 12.56-7.19 17.36-4.79 4.79-10.58 7.19-17.35 7.19h-14.73v29.46h-19.64v-78.55h34.37zm0 29.46c1.34 0 2.49-.48 3.46-1.45.97-.97 1.45-2.12 1.45-3.46 0-1.34-.49-2.49-1.45-3.46-.97-.97-2.12-1.45-3.46-1.45h-14.73v9.82h14.73z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M450.6 257.46h-28.87l-3.78 9.82H396.9l30.09-78.55h18.36l30.09 78.55h-21.06l-3.78-9.82zm-7.51-19.64l-6.92-18.07-6.92 18.07h13.84z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M492.57 228.35c-10.11-17.15-18.3-30.36-24.55-39.62h22.83c3.96 5.89 7.81 11.78 11.54 17.67 3.73-5.89 7.58-11.78 11.54-17.67h22.83c-6.25 9.26-14.43 22.47-24.55 39.62v38.93h-19.64v-38.93z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M569.34 188.73c9.82 0 19.64.82 29.46 2.45v19.64c-9.82-1.64-19.64-2.45-29.46-2.45-1.34 0-2.49.49-3.46 1.45-.97.97-1.45 2.12-1.45 3.46 0 1.34.49 2.5 1.45 3.46.97.97 2.12 1.45 3.46 1.45h4.91c6.77 0 12.56 2.4 17.36 7.19 4.79 4.79 7.19 10.58 7.19 17.35 0 6.77-2.39 12.56-7.19 17.36s-10.58 7.19-17.36 7.19c-9.82 0-19.64-.82-29.46-2.45v-19.64c9.82 1.64 19.64 2.45 29.46 2.45 1.34 0 2.5-.48 3.46-1.45s1.45-2.12 1.45-3.46c0-1.34-.48-2.49-1.45-3.46-.97-.97-2.12-1.45-3.46-1.45h-4.91c-6.77 0-12.56-2.39-17.35-7.19-4.79-4.79-7.2-10.58-7.2-17.36 0-6.77 2.4-12.56 7.2-17.35 4.79-4.79 10.57-7.19 17.35-7.19z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M663.3 257.46h-28.87l-3.78 9.82h-21.06l30.09-78.55h18.36l30.09 78.55h-21.06l-3.77-9.82zm-7.51-19.64l-6.92-18.07-6.92 18.07h13.84z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M735.16 188.73c8.18 0 16.36.82 24.55 2.45v19.64c-8.18-1.64-16.36-2.45-24.55-2.45-5.43 0-10.06 1.91-13.89 5.74s-5.74 8.46-5.74 13.89 1.91 10.06 5.74 13.89 8.46 5.74 13.89 5.74c1.64 0 3.27-.03 4.91-.1v-9.72h-9.82l-2.45-19.64h31.91v46.64c-8.18 1.64-16.36 2.45-24.55 2.45-10.83 0-20.09-3.83-27.77-11.51s-11.51-16.93-11.51-27.77 3.83-20.08 11.51-27.76 16.94-11.49 27.77-11.49z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M799.22 247.64h29.46v19.64h-49.09v-78.55h49.09v19.64h-29.46v9.82h24.55l-2.45 19.64h-22.09v9.81z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M868.18 230.41v36.87h-19.64v-78.55h20.08l14.29 27.44 14.29-27.44h20.08v78.55h-19.64v-36.87l-14.73 28.28-14.73-28.28z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M956.79 247.64h29.46v19.64h-49.09v-78.55h49.09v19.64h-29.46v9.82h24.55l-2.45 19.64H956.8v9.81z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1025.76 230.41v36.87h-19.64v-78.55h20.08l19.2 36.87v-36.87h19.64v78.55h-20.08l-19.2-36.87z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1119.27 208.37v58.91h-19.64v-58.91h-14.73v-19.64h49.1v19.64h-14.73z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M361.5 300.89c6.05 0 12.11.61 18.16 1.82v14.53a92.446 92.446 0 00-18.16-1.82c-4.02 0-7.45 1.42-10.28 4.25-2.83 2.83-4.25 6.26-4.25 10.28s1.42 7.45 4.25 10.28c2.83 2.83 6.26 4.25 10.28 4.25 1.21 0 2.42-.02 3.63-.07v-7.19h-7.26l-1.82-14.53h23.61v34.5a92.446 92.446 0 01-18.16 1.82c-8.01 0-14.86-2.84-20.54-8.51s-8.51-12.53-8.51-20.54 2.84-14.86 8.51-20.54 12.53-8.53 20.54-8.53z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M430.68 300.89v14.53h-21.79v7.26h19.98l-1.82 14.53h-18.16V359h-14.53v-58.11h36.32z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M481.88 344.47h21.79V359h-36.32v-58.11h14.53v43.58z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M532.9 344.47h21.79V359h-36.32v-58.11h36.32v14.53H532.9v7.26h18.16l-1.82 14.53H532.9v7.26z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M587.55 300.89c7.26 0 14.53.61 21.79 1.82v14.53c-7.26-1.21-14.53-1.82-21.79-1.82-.99 0-1.85.36-2.56 1.07s-1.07 1.57-1.07 2.56.36 1.85 1.07 2.56c.71.71 1.57 1.07 2.56 1.07h3.63c5.01 0 9.29 1.78 12.84 5.32 3.55 3.55 5.32 7.82 5.32 12.84 0 5.01-1.78 9.29-5.32 12.84-3.55 3.55-7.82 5.32-12.84 5.32-7.26 0-14.53-.61-21.79-1.82v-14.53c7.26 1.21 14.53 1.82 21.79 1.82.99 0 1.84-.36 2.56-1.07.71-.71 1.07-1.57 1.07-2.56s-.36-1.84-1.07-2.56-1.57-1.07-2.56-1.07h-3.63c-5.01 0-9.29-1.77-12.84-5.32-3.55-3.55-5.32-7.83-5.32-12.84s1.77-9.29 5.32-12.84c3.55-3.55 7.83-5.32 12.84-5.32z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M671.44 300.89c5.01 0 9.29 1.78 12.84 5.32 3.55 3.55 5.32 7.82 5.32 12.84s-1.77 9.29-5.32 12.84c-3.55 3.55-7.83 5.32-12.84 5.32h-10.9V359h-14.53v-58.11h25.43zm0 21.79c.99 0 1.85-.36 2.56-1.07.71-.71 1.07-1.57 1.07-2.56s-.36-1.84-1.07-2.56-1.57-1.07-2.56-1.07h-10.9v7.26h10.9z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M730.45 359c-8.01 0-14.86-2.84-20.54-8.51s-8.52-12.53-8.52-20.54 2.84-14.86 8.52-20.54 12.52-8.52 20.54-8.52c8.01 0 14.86 2.84 20.54 8.52s8.51 12.52 8.51 20.54c0 8.01-2.84 14.86-8.51 20.54S738.46 359 730.45 359zm0-14.53c4.02 0 7.45-1.42 10.28-4.25 2.83-2.83 4.25-6.26 4.25-10.28s-1.42-7.45-4.25-10.28c-2.83-2.83-6.26-4.25-10.28-4.25s-7.45 1.42-10.28 4.25c-2.83 2.83-4.25 6.26-4.25 10.28s1.42 7.45 4.25 10.28c2.83 2.84 6.26 4.25 10.28 4.25z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M799.63 300.89c5.01 0 9.29 1.78 12.84 5.32 3.55 3.55 5.32 7.82 5.32 12.84s-1.77 9.29-5.3 12.82c-1.67 1.67-3.5 2.95-5.48 3.85 5.54 7.75 9.69 15.51 12.42 23.28h-16.78c-2.57-7.26-6.37-14.53-11.4-21.79h-2.51V359h-14.53v-58.11h25.42zm0 21.79c.99 0 1.85-.36 2.56-1.07.71-.71 1.07-1.57 1.07-2.56s-.36-1.84-1.07-2.56-1.57-1.07-2.56-1.07h-10.9v7.26h10.9z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M854.47 315.42V359h-14.53v-43.58h-10.9v-14.53h36.32v14.53h-10.89z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M880.06 337.21v-36.32h14.53v36.32c0 2.01.71 3.72 2.12 5.14 1.42 1.42 3.13 2.12 5.14 2.12s3.72-.71 5.14-2.12c1.42-1.42 2.12-3.13 2.12-5.14v-36.32h14.53v36.32c0 6.03-2.12 11.17-6.37 15.42s-9.39 6.37-15.42 6.37-11.17-2.12-15.42-6.37c-4.24-4.25-6.37-9.39-6.37-15.42z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M967.4 300.89c6.05 0 12.11.61 18.16 1.82v14.53a92.446 92.446 0 00-18.16-1.82c-4.02 0-7.45 1.42-10.28 4.25-2.83 2.83-4.25 6.26-4.25 10.28s1.42 7.45 4.25 10.28c2.83 2.83 6.26 4.25 10.28 4.25 1.21 0 2.42-.02 3.63-.07v-7.19h-7.26l-1.82-14.53h23.61v34.5a92.446 92.446 0 01-18.16 1.82c-8.01 0-14.86-2.84-20.54-8.51s-8.52-12.53-8.52-20.54 2.84-14.86 8.52-20.54 12.53-8.53 20.54-8.53z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1036.37 351.74h-21.36l-2.8 7.26h-15.58l22.26-58.11h13.58l22.26 58.11h-15.58l-2.78-7.26zm-5.56-14.53l-5.12-13.37-5.12 13.37h10.24z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1080.34 359h-14.53v-58.11h14.53V359z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
								<path
									d="M1113.21 300.89c7.26 0 14.53.61 21.79 1.82v14.53c-7.26-1.21-14.53-1.82-21.79-1.82-.99 0-1.85.36-2.56 1.07-.71.71-1.07 1.57-1.07 2.56s.36 1.85 1.07 2.56c.71.71 1.57 1.07 2.56 1.07h3.63c5.01 0 9.29 1.78 12.84 5.32s5.32 7.82 5.32 12.84c0 5.01-1.78 9.29-5.32 12.84s-7.82 5.32-12.84 5.32c-7.26 0-14.53-.61-21.79-1.82v-14.53c7.26 1.21 14.53 1.82 21.79 1.82.99 0 1.84-.36 2.56-1.07.71-.71 1.07-1.57 1.07-2.56s-.36-1.84-1.07-2.56-1.57-1.07-2.56-1.07h-3.63c-5.01 0-9.29-1.77-12.84-5.32-3.55-3.55-5.32-7.83-5.32-12.84s1.77-9.29 5.32-12.84c3.54-3.55 7.83-5.32 12.84-5.32z"
									fill="#143064"
									strokeWidth="3"
									stroke="#ffffff"
									strokeMiterlimit="10"
								></path>
							</g>
						</svg>
					</div>
					<div className={classes.contNavGauche}>
						<div className={classes.contLinks}>
							<ul>
								<li>
									<Scroller to="prestations2">
										Prestations
									</Scroller>
								</li>
								<li>
									<Scroller to="realisations">
										Réalisations
									</Scroller>
								</li>
								<li>
									<Scroller to="devis">
										Devis & Contact
									</Scroller>
								</li>
							</ul>
						</div>
					</div>
					<div className={classes.contPhone}>
						<a href="tel:5146387692">(514) 638-7692</a>
					</div>
				</div>
			)}
			{toggleMenu ? preventScroll() : resumeScroll()}
			{toggleMenu && (
				<nav
					className={toggleMenu ? classes.openNav : classes.closeNav}
				>
					<ul>
						<li>
							<Scroller
								to="prestations"
								onClick={() => setToggleMenu(false)}
							>
								Prestations
							</Scroller>
						</li>
						<li className={classes.ligne}></li>
						<li>
							<Scroller
								to="realisations"
								onClick={() => setToggleMenu(false)}
							>
								Réalisation
							</Scroller>
						</li>
						<li className={classes.ligne}></li>
						<li>
							<Scroller
								to="devis"
								onClick={() => setToggleMenu(false)}
							>
								Devis & contact
							</Scroller>
						</li>
					</ul>
				</nav>
			)}
		</div>
	);
};
export default Header;
